@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800;900&display=swap");
@import url("info.scss");
body {
  background: #e3f2fd;
  font-family: "Mulish", sans-serif;
}

button {
  font-family: "Mulish", sans-serif !important;
}

.loginBtn {
  margin: 0 0 1rem;
}
.formPaper {
  box-shadow: 0 5px 5px -3px #0003, 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  .logo {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    color: #634fa1;
    font-family: "Mulish", sans-serif;
  }
  .headerRight {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.dashbaordWrapper {
  font-family: "Mulish", sans-serif;
  padding: 0 !important;
  p {
    font-family: "Mulish", sans-serif;
  }
}
.topAppBar {
  background-color: #fff !important;
  position: sticky !important;
  top: 0;
  z-index: 3;
}

.drawrHeader {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 10px 0;
  button {
    margin-right: 10px;
  }
}
.pictureInput {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.charactorLimitTxt {
  margin: 0;
  padding: 5px 0 0 0;
  font-size: 12px;
  text-align: right;
}

.file-input {
  .fileName {
    margin-left: 0.5rem;
    display: inline-block;
    img {
      max-width: 100px;
    }
  }

  input[type="file"] {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .errorMessage {
    padding-top: 0;
    margin: 0.5rem 0 0;
  }
}

.inputFileWrapper {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  .fileName {
    &.addEvent {
      font-size: 15px;
      font-weight: 600;
      display: inline-flex;
      padding-left: 10px;
      span {
        color: #e24c3f;
        padding-right: 5px;
      }
    }
  }
}

.addEventRateWrapper {
  h6 {
    margin: 0;
    padding: 30px 0 0 0;
  }
}

.pdfInput {
  display: inline-flex;
}

.primary {
  background-color: #634fa1 !important;
}
.secondary {
  background-color: #660066 !important;
}

.sidebarWrapper {
  background: #493d55;
  height: 100vh;
}
.adminNavigation {
  height: calc(100vh - 160px);
  overflow-y: auto;
  ul {
    li {
      a {
        &.nav-link {
          color: #666;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          text-decoration: none;
          width: 100%;
          padding: 12px 16px;
          font-family: "Mulish", sans-serif;
          svg {
            margin-right: 10px;
          }
          &.activated {
            background: #eee8ff;
            width: 95%;
            border-radius: 0 2rem 2rem 0;
            color: #634fa1;
          }
        }
      }
    }
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: #9782da;
    border: 0px none #fff;
    border-radius: 0px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #9782da;
  }
  &::-webkit-scrollbar-thumb:active {
    background: #9782da;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  &::-webkit-scrollbar-track:active {
    background: transparent;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}
.joinOurTeamWrapper {
  font-family: "Mulish", sans-serif;
  padding: 30px !important;
}
.tableContent {
  th {
    font-family: "Mulish", sans-serif;
    font-weight: 700;
  }
  td {
    font-family: "Mulish", sans-serif;
    font-weight: 400;
    table {
      td {
        padding: 0;
        border: none;
      }
    }
  }
  tbody {
    th {
      font-weight: 400;
    }
  }
  h4 {
    font-family: "Mulish", sans-serif;
  }
}

Main {
  min-height: 100vh;
  transition: all 0.3s ease 0s;
  &[open] {
    width: calc(100% - 280px);
    margin-left: 280px;
  }
}

.customDrawer {
  min-width: 280px;
  background-color: #fff;
  margin-left: -280px;
  position: fixed;
  transition: all 0.3s ease 0s;
  &[open] {
    margin-left: 0;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
  }
}

h2 {
  &.dialogHeadWithIcon {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-weight: 600;
    font-family: "Mulish", sans-serif;
    svg {
      margin-right: 10px;
      color: #634fa1;
    }
  }
}

.dialogContent {
  font-family: "Mulish", sans-serif !important;
}

.dailogBtnActWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
  padding: 0 12px 16px 16px;
  button {
    margin: 0 4px;
  }
}

.errorMessage {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  color: #d32f2f;
  padding-top: 20px;
}
.inputLable {
  background: #fff;
  padding-right: 5px !important;
}

.tabBtn {
  max-width: 100% !important;
}

.saveEvent {
  position: fixed !important;
  bottom: 50px;
  right: 50px;
}

.topBarButtonWrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;

  button {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    &.customButton {
      margin-left: 1rem;
      position: relative;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
      outline: 0px;
      border: 0px;
      margin: 0px;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      text-decoration: none;
      font-family: Mulish, sans-serif;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.75;
      text-transform: uppercase;
      min-width: 64px;
      padding: 6px 16px;
      border-radius: 4px;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      color: rgb(255, 255, 255);
      background-color: rgb(99, 79, 161);
      box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
    }
  }
}

.displayOrderCol {
  white-space: nowrap;
}
img {
  &.bannerSlideThumbnail {
    max-height: 60px;
  }
}
.hide {
  display: none;
}

.sorting {
  cursor: pointer;
  width: 12px;
  text-align: center;
  margin-left: 0.25rem;
  display: inline-block;
  img {
    vertical-align: baseline;
  }
}

.editEventPdfDownloadLink {
  margin-left: 1rem;
  display: inline-block;
  color: #684fa1;
  text-decoration: underline;
}

.dashbaordWrapper {
  .dashboard_chart {
    padding: 1rem 1rem 0;

    h6 {
      margin: 0 0 1rem;
      font-weight: 700;
    }
  }

  .dashboard_card {
    padding: 1rem;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 210px;
      height: 210px;
      border-radius: 50%;
      top: -125px;
      right: -15px;
      opacity: 0.5;
    }

    &:after {
      content: "";
      position: absolute;
      width: 210px;
      height: 210px;
      border-radius: 50%;
      top: -85px;
      right: -95px;
    }

    .dashboard_card_icon {
      border-radius: 8px;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
    }

    .number_txt {
      margin: 14px 8px 6px 0px;
      line-height: 1;
      font-size: 3rem;
      font-weight: 700;
    }

    &.purple {
      background: #5e35b1;
      &:before {
        background: #4527a0;
      }
      &:after {
        background: #4527a0;
      }
      .label_txt {
        color: #b39ddb;
      }
      .dashboard_card_icon {
        background-color: #4527a0;
        color: #fff;
      }
      .number_txt {
        color: #fff;
      }
    }

    &.blue {
      background: #1e88e5;
      &:before {
        background: #1565c0;
      }
      &:after {
        background: #1565c0;
      }
      .dashboard_card_icon {
        background-color: #1565c0;
        color: #fff;
      }
      .label_txt {
        color: #90caf9;
      }
      .number_txt {
        color: #fff;
      }
    }

    &.white {
      &:before {
        background: #ffc107;
      }
      &:after {
        background: #ffc107;
      }
      .dashboard_card_icon {
        color: #ffc107;
        background-color: #fff8e1;
      }
      .number_txt {
        color: #000;
      }
    }

    &.skyBlue {
      &:before {
        background: #90caf9;
      }
      &:after {
        background: #90caf9;
      }
      .dashboard_card_icon {
        color: #fff;
        background-color: #90caf9;
      }
      .number_txt {
        color: #1565c0;
      }
    }
  }
}

.competitionGuidelineInputButton {
  min-width: 300px !important;
}

.ck-editor__editable {
  min-height: 300px;
  max-height: 300px;
  overflow: auto;
}

.notFoundWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  height: 100vh;
  .notFountData {
    padding: 50px 15px;
    width: 100%;
    max-width: 600px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    .notFoundImg {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      img {
        max-width: 400px;
        width: 100%;
      }
    }
    h3 {
      text-align: center;
      padding: 20px 0 0 0;
      font-weight: 700;
      font-size: 32px;
      line-height: 50px;
      text-transform: uppercase;
      color: #000;
    }
    p {
      margin: 0;
      padding: 0 0 20px 0;
      font-weight: 400;
      font-size: 18px;
      line-height: 36px;
      text-align: center;
      text-transform: capitalize;
      color: #000;
    }
  }
}

label[for="fee_ten_to_nineteen"] {
  padding-right: 5px;
  background: #fff;
}

.selectPageDropdown {
  label {
    padding-right: 5px;
    background: #fff;
  }
}
