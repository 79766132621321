.recomemdedText {
    padding: 0;
    margin: 0;
    font-size: 12px;
    line-height: 22px;
    display: block;
    width: 100%;
    &.inline {
        width: auto;
        display: inline-block;
        padding: 5px 0 0 10px;
    }
}
.eventImage {
    max-width: 100px;
    margin-top: 10px;
}
.locationImage {
    h6 {
        padding: 0 0 10px 0;
    }
    img {
        max-width: 100%;
    }
}
img {
    &.slideImage {
        max-width: 100px;
        margin-top: 10px;
    }
}
